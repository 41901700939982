import React, { FC } from 'react';
import { FullScreenIconElement } from './FullScreenIcon.styles';

interface Props {
  onClick?: (event: React.UIEvent) => void;
  displayAlways?: boolean;
}

const FullScreenIcon: FC<Props> = ({ onClick, displayAlways }) => (
  <FullScreenIconElement onClick={onClick} $displayAlways={displayAlways} />
);

export default FullScreenIcon;

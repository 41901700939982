import styled from 'styled-components';
import { ReactComponent as FullScreen } from 'assets/icons/fullscreen.svg';

export const FullScreenIconElement = styled(FullScreen)<{ $displayAlways?: boolean }>`
  position: absolute;
  display: block;
  right: 10px;
  bottom: 10px;
  padding: 9px;
  z-index: 8;
  width: 32px;
  height: 32px;
  background-color: ${({ theme }) => theme.palette.mask.weaker};
  backdrop-filter: blur(10px);
  border-radius: 6px;
  pointer-events: none;
  @media (hover: hover) {
    display: ${(props) => (props.$displayAlways ? 'block' : 'none')};
  }
`;

export default { FullScreenIconElement };

import React, { useEffect, useState, useRef } from 'react';
import { useIntl } from 'react-intl';
import { observer } from 'mobx-react';
import {
  Image,
  NavigationButton,
  TrackInfoButton,
  RichText,
  Button,
  GamificationSteps,
  Steps,
  AdminModal,
  CompletedLabel,
  FinishModal,
} from 'components';
import { Prompt } from 'react-router-dom';
import { generatePath } from 'react-router';
import { RouteTemplates, ProgressBarWidth, ScreenNames } from '_constants';
import { sendAnalyticsEvent, useAppNavigator, useBreadcrumb, useVisibility } from 'utils';
import SectionKey from 'views/LearningTracks/constants/SectionKey';
import store from 'store';
import { ReactComponent as InfoIcon } from 'assets/icons/checkmark-button.svg';
import { LearningTracksStatuses, LearningTrackFormat } from 'models/LearningTracks/constants';
import { LearningTracksModalOptions } from 'types/completeModalLearningTracks';
import FullScreenIcon from 'components/Image/FullScreenIcon';
import {
  NavigationButtonOverlay,
  Wrapper,
  Card,
  CourseImageOverlay,
  StyledTitle,
  TextOverlay,
  ModalButton,
  ProgressStageOverlay,
  StyledProgressStage,
  SubmitWrapper,
  InfoButtonWrapper,
} from './LearningTrack.styles';
import messages from './messages';

const LearningTrack: React.FC = () => {
  const {
    params: { alias, trackId },
    goToLearningTracksSection,
  } = useAppNavigator();

  const intl = useIntl();

  const {
    components: { myLearningTracksComponent },
    learningTrack: { track, completedSteps, totalSteps, fetchRequiredData },
  } = store.DomainStore;
  const {
    learningTrack: { finishTrackModal, finishTrackId },
  } = store.UIStore;
  const [isVisibleDescription, setVisibleDescription] = useState(false);
  const [isVisibleButtonTrack, setVisibleButtonTrack] = useState(true);
  const scrollRef = useRef<HTMLDivElement>(null);
  const { setScrollPosition, scrollPositions } = store.UIStore.layout;

  const percent = (completedSteps && totalSteps && Math.round((completedSteps / totalSteps) * 100)) || 0;

  const adminModalVisibility = useVisibility();

  useBreadcrumb({
    items: [
      {
        name: myLearningTracksComponent?.name || '',
        location: generatePath(RouteTemplates.myLearningTracksAll, {
          alias,
        }),
      },
      {
        name: track?.name || '',
        location: generatePath(RouteTemplates.myLearningTrack, {
          alias,
          trackId,
        }),
      },
    ],
    onBackLocation: generatePath(RouteTemplates.myLearningTracksAll, {
      alias,
    }),
  });

  useEffect(() => {
    if (!store.UIStore.learningTrack.isFetchingTrack) {
      fetchRequiredData(trackId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (track && track.id === trackId) {
      sendAnalyticsEvent('screen_view', {
        firebase_screen: isVisibleDescription ? ScreenNames.TRACK_DESCRIPTION : ScreenNames.TRACK_STEPS,
        track_id: track.id,
        track_name: track.name,
        track_format: track.style,
      });
    }
  }, [trackId, track, track?.id, isVisibleDescription]);

  useEffect(() => {
    if (!store.UIStore.learningTrack.isFetchingTrack) return;
    const scrollTop = scrollPositions.get(window.location.href)?.scrollTop;
    if (scrollRef.current) {
      scrollRef.current?.scrollTo(0, scrollTop || 0);
    }
  }, [scrollPositions, scrollRef]);

  const onStartTrack = () => {
    setVisibleButtonTrack((current) => !current);
    if (track) {
      sendAnalyticsEvent('track_started_button_tapped', {
        track_id: track.id,
        track_name: track.name,
      });
    }
  };

  const onOkFinishModal = () => {
    finishTrackModal.hide();
    if (track) {
      sendAnalyticsEvent('track_completed_button_tapped', {
        track_id: track.id,
        track_name: track.name,
      });
    }
  };

  return (
    <>
      <FinishModal
        title={track?.finalScreen.title}
        text={track?.finalScreen.text}
        buttonText={intl.formatMessage(messages.finishModalOkButtonText)}
        visible={finishTrackId === trackId && finishTrackModal.visible}
        onClose={finishTrackModal.hide}
        onOk={onOkFinishModal}
        option={LearningTracksModalOptions.TRACK}
      />
      <NavigationButtonOverlay>
        <NavigationButton
          inlineText={intl.formatMessage(messages.backButtonText)}
          onClick={() => goToLearningTracksSection(SectionKey.ALL)}
        />
      </NavigationButtonOverlay>
      <Wrapper ref={scrollRef}>
        <Prompt
          message={(locationPrompt, action) => {
            if (scrollRef.current?.scrollTop !== undefined && action === 'PUSH') {
              setScrollPosition(window.location.href, scrollRef.current?.scrollTop);
            }
            return true;
          }}
        />
        <Card>
          {track?.coverThumbnailUrl && track?.coverUrl && (
            <CourseImageOverlay>
              <Image thumbnail={track?.coverThumbnailUrl || ''} original={track?.coverUrl} preview />
              <FullScreenIcon />
            </CourseImageOverlay>
          )}
          {track?.viewInAdminMode && (
            <>
              <ModalButton type="primary" onClick={adminModalVisibility.show}>
                {intl.formatMessage(messages.modalButtonText)}
                <InfoIcon />
              </ModalButton>
              <AdminModal visible={adminModalVisibility.visible} onClose={adminModalVisibility.hide} />
            </>
          )}
          <StyledTitle level={2}>{track?.name}</StyledTitle>
          {isVisibleDescription || (isVisibleButtonTrack && !track?.openDate) || !totalSteps ? (
            <>
              {track?.description && (
                <TextOverlay>
                  <RichText content={track?.description} />
                </TextOverlay>
              )}
            </>
          ) : (
            <>
              {track?.progress.isEnabled && (
                <ProgressStageOverlay>
                  {track?.status === LearningTracksStatuses.COMPLETED ? (
                    <CompletedLabel
                      resultText={track?.progress.text || intl.formatMessage(messages.defaultCompletedText)}
                      passedStageCount={completedSteps}
                      stageCount={totalSteps}
                    />
                  ) : (
                    <StyledProgressStage
                      title={intl.formatMessage(messages.completedOf, {
                        completedText: track?.progress.text || intl.formatMessage(messages.defaultCompletedText),
                        stagesPassed: completedSteps,
                        stagesCount: totalSteps,
                      })}
                      percent={Math.max(percent, ProgressBarWidth.XS)}
                      percentVisibleText={percent}
                    />
                  )}
                </ProgressStageOverlay>
              )}
              {track?.style === LearningTrackFormat.GAME ? (
                <GamificationSteps steps={track?.steps} viewInAdminMode={track?.viewInAdminMode} />
              ) : (
                <Steps steps={track?.steps} viewInAdminMode={track?.viewInAdminMode} />
              )}
            </>
          )}
          {isVisibleButtonTrack && !!totalSteps && !track?.openDate && (
            <SubmitWrapper>
              <Button type="primary" onClick={onStartTrack}>
                {intl.formatMessage(messages.submitButtonText)}
              </Button>
            </SubmitWrapper>
          )}
        </Card>
        {!(isVisibleButtonTrack && !track?.openDate) && !!totalSteps && !!track?.description && (
          <InfoButtonWrapper>
            <TrackInfoButton
              onClick={() => setVisibleDescription((current) => !current)}
              active={isVisibleDescription}
              text={
                isVisibleDescription
                  ? intl.formatMessage(messages.showContentButtonText)
                  : intl.formatMessage(messages.showDescriptionButtonText)
              }
            />
          </InfoButtonWrapper>
        )}
      </Wrapper>
    </>
  );
};

export default observer(LearningTrack);

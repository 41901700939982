// eslint-disable-next-line max-classes-per-file
import { UploadProps } from 'antd/lib/upload/interface';
import {
  TResponded,
  TTask,
  TTaskInfo,
  TTaskSendSubmission,
  TTaskStartSubmission,
  TTaskUpdateSubmission,
  TTaskFields,
  TTaskAttendeesFields,
} from './index';

export type TTasksInfoResponse = TResponded<TTaskInfo[]>;

export type TTaskResponse = TResponded<TTask>;

export type TTaskStartSubmissionResponse = TResponded<TTaskStartSubmission>;

export type TTaskSendSubmissionAnswerRequest = {
  fieldId: number;
  value?: string | number | null;
  attachmentId?: number | null;
  optionIds?: number[];
  attendeeIds?: number[];
};

export type TTaskSendSubmissionRequest = {
  taskId: number;
  submissionId: number;
  answers: TTaskSendSubmissionAnswerRequest[];
  isDraft?: boolean;
};

export type TTaskSendSubmissionResponse = TResponded<TTaskSendSubmission>;

export type TTaskUpdateSubmissionResponse = TResponded<TTaskUpdateSubmission>;

export type UploadFileOptions<T = unknown> = Parameters<Exclude<UploadProps<T>['customRequest'], undefined>>[0];

export type TTaskAttachmentRequest = {
  taskId: number;
  attachmentId: number;
};

export type TFieldAnswerAttachmentRequest = {
  taskId: number;
  submissionId: number;
  fieldId: number;
  attachmentId: number;
};

export type TFeedbackAttachmentRequest = {
  taskId: number;
  submissionId: number;
  attachmentId: number;
};

export type TTaskAttachmentInfo = {
  id: number;
  name: string;
  size: number;
  url: string;
};

export type TTaskFieldsRequest = {
  taskId: number;
  fieldId: number;
};

export type TTaskAttachmentResponse = TResponded<TTaskAttachmentInfo>;

export type TTaskFieldsResponse = TResponded<TTaskFields>;

export type TTaskAttendeesFieldsResponse = TResponded<TTaskAttendeesFields>;

export class FileSizeTooLargeError extends Error {}

export class FileNameTooLongError extends Error {}

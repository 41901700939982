/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["self"] }] */
import { SnapshotOut, cast, flow, types, Instance, getRoot, getSnapshot } from 'mobx-state-tree';
import {
  MCurrentUserAnswer,
  PollStatus,
  MStatistic,
  QuestionType,
  PollValueType,
  PollShowRightAnswerType,
  MScreen,
} from 'models/Polls';
import { pollsApi } from 'api';
import { MPoll } from 'models';
import { TAnswer, TQuestion, TStore, TResponded } from 'types';
import { TPollsResponse, TPollResponse, TStatisticsResponse } from 'types/pollsApiTypes';
import {
  getShuffledArray,
  getArraySortedByOrderMap,
  getOrderedArray,
  getOrderedScreensArray,
  getScreensArraySortedByOrderMap,
  getShuffledScreensArray,
} from 'utils';

export const MQuestionsCurrentAnswers = types
  .model({
    questionId: types.identifierNumber,
    options: types.maybeNull(types.array(MCurrentUserAnswer)),
    isSent: false,
  })
  .actions((self) => ({
    setIsSent: (isSent: boolean) => {
      self.isSent = isSent;
    },
  }));

export const CurrentAnswers = types.model({
  pollId: types.identifierNumber,
  pollAnswers: types.map(MQuestionsCurrentAnswers),
});

export const OrderedOption = types.model({
  order: types.number,
});
export const OrderedQuestion = types.model({
  order: types.number,
  orderedOptions: types.map(OrderedOption),
});
export const OrderedScreen = types.model({
  order: types.number,
  orderedQuestions: types.map(OrderedQuestion),
});
export const OrderedPoll = types.model({
  orderedScreens: types.map(OrderedScreen),
  shuffleQuestions: types.maybeNull(types.boolean),
  shuffleOptions: types.maybeNull(types.boolean),
});

const Poll = types
  .model({
    data: types.maybeNull(MPoll),
    resultScreens: types.array(MScreen),
    statistics: types.array(MStatistic),
    answers: types.map(CurrentAnswers),
    myPolls: types.array(MPoll),
    pollsPassingOrder: types.map(OrderedPoll),
    pollsResultsOrder: types.map(OrderedPoll),
  })
  .views((self) => ({
    get currentAnswers() {
      return self.data?.id ? self.answers.get(String(self.data?.id))?.pollAnswers : null;
    },
    get showTotalPollResults() {
      return (
        self.data?.typeValue === PollValueType.COMMON ||
        self.data?.showRightAnswerTypeValue === PollShowRightAnswerType.ATTENDEE_WITH_TOTAL
      );
    },
  }))
  .views((self) => ({
    get activeMyPolls() {
      return self.myPolls.filter((poll) => poll.status === PollStatus.ACTIVE && !poll.isCompleted);
    },
    get pendingMyPolls() {
      return self.myPolls.filter((poll) => poll.status === PollStatus.PENDING);
    },
    get finishedMyPolls() {
      return self.myPolls.filter((poll) => poll.status === PollStatus.FINISHED);
    },
    hasUnansweredPreviousScreens(screenId: number) {
      if (!self.data) return false;
      const { screens } = self.data;
      for (let i = 0; i < screens.length; i += 1) {
        if (screens[i].id === screenId) return false;
        if (screens[i].questions.some((question) => !self.currentAnswers?.get(String(question.id)))) return true;
      }
      return false;
    },
    getShuffledScreens: (data: SnapshotOut<typeof MPoll> | null, pollOrders?: Instance<typeof OrderedPoll>) => {
      if (!data) return [];
      const { shuffleQuestions, shuffleOptions } = data;
      const currentAnswers = self.answers.get(String(data.id))?.pollAnswers;

      if (data.typeValue !== PollValueType.TEST_WITH_ANSWERS || !(shuffleQuestions || shuffleOptions)) {
        const screensWithOrderedQuestions = data.screens.map((screen) => ({
          ...screen,
          questions: screen.questions.map((question) => ({
            ...question,
            options: getOrderedArray(question.options),
          })),
        }));
        return getOrderedScreensArray(screensWithOrderedQuestions, currentAnswers);
      }

      let shuffledScreens;
      // Cкрины с перемешанными вопросами
      const screensWithShuffledQuestions = data.screens.map((screen) => {
        const screenOrders = pollOrders?.orderedScreens?.get(String(screen.id));
        // Вопросы с перемешанными вариантами
        const questionsWithShuffledOptions = screen.questions.map((question) => {
          let shuffledOptions;
          const questionOrders = screenOrders?.orderedQuestions?.get(String(question.id));
          if (shuffleOptions) {
            if (questionOrders) {
              shuffledOptions = getArraySortedByOrderMap(question.options, questionOrders.orderedOptions);
            } else shuffledOptions = getShuffledArray(question.options);
          } else shuffledOptions = getOrderedArray(question.options);
          return {
            ...question,
            options: shuffledOptions,
          };
        });

        let shuffledQuestions;
        if (shuffleQuestions) {
          if (screenOrders) {
            shuffledQuestions = getArraySortedByOrderMap(questionsWithShuffledOptions, screenOrders.orderedQuestions);
          } else shuffledQuestions = getShuffledArray(questionsWithShuffledOptions);
        } else shuffledQuestions = questionsWithShuffledOptions;

        return { ...screen, questions: shuffledQuestions };
      });

      if (shuffleQuestions) {
        if (pollOrders) {
          shuffledScreens = getScreensArraySortedByOrderMap(
            screensWithShuffledQuestions,
            pollOrders.orderedScreens,
            currentAnswers,
          );
        } else {
          shuffledScreens = getShuffledScreensArray(screensWithShuffledQuestions, currentAnswers);
        }
      } else {
        shuffledScreens = getOrderedScreensArray(screensWithShuffledQuestions, currentAnswers);
      }
      return shuffledScreens;
    },
  }))
  .views((self) => ({
    get completedMyPolls() {
      return self.myPolls.filter((poll) => poll.status !== PollStatus.PENDING && poll.isCompleted);
    },
    get missedMyPolls() {
      return self.finishedMyPolls.filter((poll) => !poll.isCompleted);
    },
    get activeMyPollsCount() {
      return self.activeMyPolls.length;
    },
    get pendingMyPollsCount() {
      return self.pendingMyPolls.length;
    },
    findPoll(pollId: number) {
      return self.myPolls.find(({ id }) => id === pollId);
    },
  }))
  .views((self) => ({
    get courseUIStore() {
      return getRoot<TStore>(self).UIStore.course;
    },
    get allResultsScreens() {
      return self.resultScreens?.filter(({ questionsForResult }) => !!questionsForResult.length) || [];
    },

    getScreenQuestionsHasCurrentAnswers: (screenId: number) => {
      return !!self.data
        ?.getScreen(screenId)
        ?.questions.every(({ id }) => !!self.currentAnswers?.get(String(id))?.options?.length);
    },
    getScreenAnswersIsSent: (screenId: number) => {
      return !!self.data
        ?.getScreen(screenId)
        ?.questions.every(({ id }) => !!self.currentAnswers?.get(String(id))?.isSent);
    },
  }))
  .views((self) => ({
    getOptionStatisticPercent: (questionId: number, optionId: number) => {
      const questionStatistic = self.statistics.find(({ questionId: id }) => id === questionId);
      if (!questionStatistic) return 0;
      const { totalAnswers } = questionStatistic;
      const selectedOptionStatistic = questionStatistic.selectedOptions?.find(({ optionId: id }) => optionId === id);
      if (!selectedOptionStatistic || !totalAnswers) return 0;
      const { count } = selectedOptionStatistic;
      return Math.round((count / totalAnswers) * 100);
    },
    getOptionStatisticStars: (questionId: number) => {
      const questionStatistic = self.statistics.find(({ questionId: id }) => id === questionId);
      return questionStatistic?.stars?.avg || 0;
    },
    getQuestionUserScore: (question: TQuestion) => {
      if (self.data?.showRightAnswerAfterAnswerComplete) {
        const answer = self.currentAnswers?.get(String(question.id));
        if (answer?.options?.length) return question.getUserScore(answer.options);
      }
      if (!self.data) return null;
      return question.getUserScore(self.data.answers);
    },
    getScreenUserScore: (screenId: number) => {
      if (!self.data) return null;
      const screen = self.data.screens.find(({ id }) => id === screenId);
      const question = screen?.questions[0];
      if (!screen || !question) return null;
      const answers = self.currentAnswers?.get(String(question.id));
      return question.getUserScore(answers?.options || []);
    },
    get pollUserScore() {
      if (!self.data) return 0;
      return self.data.getUserScore(self.data?.answers);
    },
    getPollWithoutAnswersScore: () => {
      if (!self.data) return 0;
      return self.data.getUserScoreWithoutAnswers(self.data?.answers);
    },
    getQuestionResultAnswersInfo: (question: TQuestion) => {
      if (!self.data) return null;
      return question.getAnswerInfo(self.data.answers);
    },
    getQuestionQuickResultAnswersInfo: (question: TQuestion) => {
      const answer = self.currentAnswers?.get(String(question.id))?.options;
      if (!self.data || !answer) return null;
      return question.getAnswerInfo(answer);
    },
    getScreenCurrentAnswersInfo: (screenId: number) => {
      if (!self.data) return null;
      const screen = self.data.screens.find(({ id }) => id === screenId);
      const question = screen?.questions[0];
      if (!screen || !question || !screen.hasQuickResult) return null;
      const answers = self.currentAnswers?.get(String(question.id));
      return question.getAnswerInfo(answers?.options || []);
    },
    getCanMoveOn: (screenId: number) => {
      return !!self.data?.canSkipQuestions || self.getScreenQuestionsHasCurrentAnswers(screenId);
    },
    getProgress: (screenId: number) => {
      const answersIsSent = self.data?.showRightAnswerAfterAnswerComplete && self.getScreenAnswersIsSent(screenId);
      const screens = self.data?.screens || [];
      const currentIndex = screens?.findIndex(({ id }) => screenId === id);

      return {
        current: currentIndex + 1,
        total: screens.length,
        percent: screens.length
          ? Math.round(((answersIsSent ? currentIndex + 1 : currentIndex) / screens.length) * 100)
          : 0,
      };
    },
  }))
  .actions((self) => ({
    createPollAnswers() {
      if (!self.data?.id) return;
      self.answers?.put({
        pollId: self.data?.id,
        pollAnswers: {},
      });
    },
    hasBreakingChanges(poll: SnapshotOut<typeof MPoll>) {
      const currentPollOrders = self.pollsPassingOrder.get(String(poll.id));
      if (!currentPollOrders) return false;
      if (currentPollOrders.orderedScreens.size !== poll.screens.length) return true;
      for (let i = 0; i < poll.screens.length; i += 1) {
        const screen = poll.screens[i];
        const screenOrders = currentPollOrders.orderedScreens.get(String(screen.id));
        if (!screenOrders || screenOrders.orderedQuestions.size !== screen.questions.length) return true;
        for (let j = 0; j < screen.questions.length; j += 1) {
          const question = screen.questions[j];
          const questionOrders = screenOrders.orderedQuestions.get(String(question.id));
          if (!questionOrders || questionOrders.orderedOptions.size !== question.options.length) return true;
          for (let k = 0; k < question.options.length; k += 1) {
            if (!questionOrders.orderedOptions.has(String(question.options[k].id))) return true;
          }
        }
      }

      return false;
    },
    convertToOrdersObject: (shuffledScreens: SnapshotOut<typeof MScreen>[]) => {
      const screensOrders = shuffledScreens.reduce((prevScreenOrders, screen, screenIndex) => {
        const questionOrders = screen.questions?.reduce(
          (prevQuestionOrders, question, questionIndex) => ({
            ...prevQuestionOrders,
            [question.id]: {
              id: question.id,
              order: questionIndex,
              orderedOptions: question.options?.reduce(
                (prevOptionOrders, option, optionIndex) => ({
                  ...prevOptionOrders,
                  [option.id]: { id: option.id, order: optionIndex },
                }),
                {},
              ),
            },
          }),
          {},
        );
        return {
          ...prevScreenOrders,
          [screen.id]: { id: screen.id, order: screenIndex, orderedQuestions: questionOrders },
        };
      }, {});
      return screensOrders;
    },
  }))
  .actions((self) => ({
    fetchPolls: flow(function* fetchPolls() {
      const { data, hasError }: TPollsResponse = yield pollsApi.getPolls();
      if (!hasError && data) {
        self.myPolls = cast(data);
      }
    }),
    resetAnswer: (questionId: number) => {
      self.currentAnswers?.put({
        questionId,
        options: null,
      });
    },
    resetCurrentAnswers: () => {
      self.currentAnswers?.clear();
    },
  }))
  .actions((self) => {
    const setData = (data: SnapshotOut<typeof MPoll>) => {
      const { shuffleQuestions, shuffleOptions } = data;
      // Упорядочиваем порядок скринов, вопросов и вариантов текущего прохождения
      if (data.typeValue === PollValueType.TEST_WITH_ANSWERS && (shuffleQuestions || shuffleOptions)) {
        let currentPollOrders = self.pollsPassingOrder.get(String(data.id));
        // Если поменялся тип перемешивания очищаем предыдущий порядок
        if (
          currentPollOrders &&
          (shuffleQuestions !== currentPollOrders.shuffleQuestions ||
            shuffleOptions !== currentPollOrders.shuffleOptions)
        ) {
          self.pollsPassingOrder.delete(String(data.id));
          currentPollOrders = undefined;
        }
        const hasBreakingChanges = !!currentPollOrders && self.hasBreakingChanges(data);
        const shuffledScreens = self.getShuffledScreens(data, currentPollOrders);
        self.data = cast({ ...data, screens: shuffledScreens });

        // Записываем порядок вопросов и вариантов ответа
        if (!currentPollOrders || hasBreakingChanges) {
          self.pollsPassingOrder.set(String(data.id), {
            orderedScreens: self.convertToOrdersObject(shuffledScreens),
            shuffleQuestions,
            shuffleOptions,
          });
        }
        const currentPollResultsOrders = self.pollsResultsOrder.get(String(data.id));
        self.resultScreens = cast(
          self.getShuffledScreens(data, currentPollResultsOrders || self.pollsPassingOrder.get(String(data.id))),
        );
      } else {
        self.pollsPassingOrder.delete(String(data.id));
        const orderedScreens = self.getShuffledScreens(data);
        self.data = cast({ ...data, screens: orderedScreens });
        self.resultScreens = cast(orderedScreens);
      }
    };
    const rewriteAnswersFromApi = (data: SnapshotOut<typeof MPoll>) => {
      if (data.showRightAnswerAfterAnswerComplete && !data.isCompleted) {
        const answersFromApi: Record<string, TAnswer[]> = {};
        const pollAnswersMap: Record<number, SnapshotOut<typeof MQuestionsCurrentAnswers>> = {};
        if (!data.answers.length) {
          self.resetCurrentAnswers();
        } else {
          for (let i = 0; i < data.answers.length; i += 1) {
            const answer = data.answers[i];
            answersFromApi[answer.questionId] = answersFromApi[answer.questionId]
              ? [...answersFromApi[answer.questionId], answer]
              : [answer];
          }
          Object.keys(answersFromApi).forEach((questionId) => {
            const screenId = data.screens.find((screen) =>
              screen.questions.find((question) => question.id === Number(questionId)),
            )?.id;
            if (!screenId) return;

            pollAnswersMap[Number(questionId)] = MQuestionsCurrentAnswers.create({
              questionId: Number(questionId),
              options: answersFromApi[questionId].map((option) =>
                MCurrentUserAnswer.create({
                  questionId: Number(questionId),
                  screenId,
                  starLevel: option.starLevel,
                  optionId: option.optionId,
                  text: option.text,
                }),
              ),
              isSent: true,
            });
          });

          self.answers.set(String(data.id), { pollId: data.id, pollAnswers: pollAnswersMap });
        }
      }
    };

    return {
      fetchStatistics: flow(function* fetchStatistics(pollId: number, trackId?: string) {
        const { data, hasError }: TStatisticsResponse = yield pollsApi.getStatistics(pollId, trackId);
        if (data && !hasError) {
          self.statistics = cast(data);
        }
        return { data, hasError };
      }),
      fetchPoll: flow(function* fetchPoll(pollId: number, trackId?: string, fromCourses?: boolean) {
        if (fromCourses) self.courseUIStore.setFetchingPoll(true);
        const { data, hasError }: TPollResponse = yield pollsApi.openPoll(pollId, trackId);
        if (data && !hasError) {
          rewriteAnswersFromApi(data);
          setData(data);
        }
        if (!self.currentAnswers) {
          self.createPollAnswers();
        }
        if (fromCourses) self.courseUIStore.setFetchingPoll(false);
        return { data, hasError };
      }),
    };
  })
  .actions((self) => {
    const movePollPassingOrdersToResultsOrders = () => {
      const poll = self.data;
      if (!poll) return;
      const strPollId = String(poll.id);
      if (poll && poll.typeValue === PollValueType.TEST_WITH_ANSWERS) {
        const currentPollPassingOrder = self.pollsPassingOrder.get(strPollId);
        if (currentPollPassingOrder) {
          if (poll.shuffleQuestions || poll.shuffleOptions) {
            if (poll.showRightAnswerAfterTestComplete) {
              self.pollsResultsOrder.set(strPollId, getSnapshot(currentPollPassingOrder));
            }
            self.pollsPassingOrder.delete(strPollId);
          } else {
            self.pollsPassingOrder.delete(strPollId);
            self.pollsResultsOrder.delete(strPollId);
          }
        }
      }
    };
    return {
      passAgain: flow(function* passAgain(pollId: number, trackId?: string) {
        self.courseUIStore.setFetchingPoll(true);
        const restartPollResponse = yield pollsApi.restartPoll(pollId, trackId);
        const fetchPollResponse = yield self.fetchPoll(pollId, trackId);
        self.courseUIStore.setFetchingPoll(false);
        if (restartPollResponse.hasError || fetchPollResponse.hasError) {
          return false;
        }
        return true;
      }),
      finish: flow(function* finish(courseId: number, stageId: number, pollId: number, trackId?: string) {
        const answers = Array.from(self.currentAnswers?.values() || []).reduce<Instance<typeof MCurrentUserAnswer>[]>(
          (acc, { options }) => (options ? acc.concat(options) : acc),
          [],
        );
        const filteredAnswers = answers.filter(({ screenId, optionId, questionId }) => {
          const screen = self.data?.screens.find(({ id }) => id === screenId);
          const question = screen?.questions.find(({ id }) => id === questionId);
          return (
            question?.type === QuestionType.FREE_TEXT ||
            question?.type === QuestionType.STARS ||
            question?.options.some(({ id }) => optionId === id)
          );
        });
        if (!filteredAnswers) return false;
        const postAnswersResponse: TResponded<null> = yield pollsApi.finish(
          {
            courseId,
            stageId,
            pollId,
            answers: filteredAnswers,
          },
          trackId,
        );
        if (postAnswersResponse.hasError) return false;
        movePollPassingOrdersToResultsOrders();
        const fetchPollResponse = yield self.fetchPoll(pollId, trackId, true);
        if (fetchPollResponse.hasError) return false;
        return true;
      }),
      finishMyPoll: flow(function* finishMyPoll(pollId: number, trackId?: string) {
        const answers = Array.from(self.currentAnswers?.values() || []).reduce<Instance<typeof MCurrentUserAnswer>[]>(
          (acc, { options }) => (options ? acc.concat(options) : acc),
          [],
        );

        if (!answers) return false;
        const postPollAnswersResponse: TPollsResponse = yield pollsApi.sendPollAnswers(
          {
            pollId,
            answers,
          },
          trackId,
        );
        if (postPollAnswersResponse.hasError) {
          return false;
        }

        movePollPassingOrdersToResultsOrders();
        const fetchPollResponse = yield self.fetchPoll(pollId, trackId);
        if (fetchPollResponse.hasError) return false;
        return true;
      }),
      finishPollStageWithQuickResult: flow(function* postAnswer(
        pollId: number,
        courseId?: number,
        stageId?: number,
        trackId?: string,
      ) {
        const { hasError }: TResponded<null> = yield pollsApi.finishPollStageWithQuickResult(
          pollId,
          trackId,
          courseId,
          stageId,
        );
        if (!hasError) movePollPassingOrdersToResultsOrders();
        const fetchPollResponse = yield self.fetchPoll(pollId, trackId);
        return { hasError: hasError || fetchPollResponse.hasError };
      }),
      postAnswer: flow(function* postAnswer(screenId: number, trackId?: string) {
        const screenAnswers =
          self.data
            ?.getScreen(screenId)
            ?.questions.map(({ id }) => self.currentAnswers?.get(String(id)))
            ?.filter((a): a is Instance<typeof MQuestionsCurrentAnswers> => !!a) || [];
        const questionAnswers = screenAnswers.map(({ questionId, options }) => ({
          questionId,
          optionIds: options?.map(({ optionId }) => optionId).filter((option) => !!option) || [],
          text: options?.[0]?.text,
          starLevel: options?.[0]?.starLevel,
          isSkipped: !!options?.[0]?.isSkipped,
        }));
        if (!questionAnswers || !self.data?.id) return;
        const pollId = self.data.id;
        const postAnswersResponse: TResponded<null>[] = yield Promise.all(
          questionAnswers.map((answer) =>
            pollsApi.postAnswer(
              {
                pollId,
                answer,
              },
              trackId,
            ),
          ),
        );
        const hasError = postAnswersResponse.some(({ hasError: error }) => error);
        if (!hasError && screenAnswers?.length) {
          screenAnswers.forEach((a) => a?.setIsSent(true));
        }
      }),
      setTextAnswer: (screenId: number, questionId: number, text: string) => {
        self.currentAnswers?.put({
          questionId,
          options: text.trim() ? [MCurrentUserAnswer.create({ screenId, questionId, text })] : null,
        });
      },
      setRatingAnswer: (screenId: number, questionId: number, rating: number) => {
        if (!rating) {
          self.resetAnswer(questionId);
          return;
        }
        self.currentAnswers?.put({
          questionId,
          options: [MCurrentUserAnswer.create({ screenId, questionId, starLevel: rating })],
        });
      },
      setSingleSelectAnswer: (screenId: number, questionId: number, optionId?: number) => {
        if (!optionId) {
          self.resetAnswer(questionId);
          return;
        }
        self.currentAnswers?.put({
          questionId,
          options: [MCurrentUserAnswer.create({ screenId, questionId, optionId })],
        });
      },
      setMultiselectAnswer: (screenId: number, questionId: number, optionIds: number[]) => {
        self.currentAnswers?.put({
          questionId,
          options: optionIds.map((id) => MCurrentUserAnswer.create({ screenId, questionId, optionId: id })),
        });
      },
    };
  });

export default Poll;

import styled from 'styled-components';
import { MIXINS, Grid } from '_constants';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

export const CatalogImageOverlay = styled.div`
  position: relative;
  margin-bottom: 24px;
  ${MIXINS.ratio16x9}
  & .ant-image {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
  }

  & .ant-image:not(.ant-image-error) {
    & .ant-image-img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-radius: 8px;
    }
  }

  & .ant-image-error {
    pointer-events: none;

    &::before,
    &::after {
      display: none;
    }

    & .ant-image-img {
      object-fit: cover;
    }
  }

  & .ant-image:not(.ant-image-error) {
    & .ant-image-img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-radius: 8px;
    }
  }

  @media (hover: hover) {
    cursor: zoom-in;
  }
`;

export const Close = styled(CloseIcon)`
  margin-left: 16px;
`;

export const DescriptionWrapper = styled.div`
  margin-top: 32px;
  @media (max-width: ${Grid.MD}px) {
    margin-top: 24px;
  }
`;

export const CatalogElementWrapper = styled.div`
  margin-top: 32px;
  @media (max-width: ${Grid.MD}px) {
    margin-top: 24px;
  }
`;

import { types } from 'mobx-state-tree';
import MThemeColor from './MThemeColor';

const MThemePalette = types.model({
  primary: MThemeColor,
  dominant: types.string,
  additionalDominant: types.string,
  dark: types.model({
    main: types.string,
    dark_2: types.string,
    dark_4: types.string,
    dark_8: types.string,
    dark_10: types.string,
    dark_12: types.string,
    dark_16: types.string,
    dark_20: types.string,
    dark_24: types.string,
    dark_30: types.string,
    dark_40: types.string,
    dark_50: types.string,
    dark_68: types.string,
    dark_76: types.string,
  }),
  violet: types.model({
    main: types.string,
    violet_76: types.string,
    violet_68: types.string,
    violet_40: types.string,
    violet_4: types.string,
  }),
  notify: types.string,
  rating: types.string,
  scrollbar: types.string,
  success: types.model({
    primary: types.string,
    secondary: types.string,
    accent: types.string,
  }),
  error: types.model({
    primary: types.string,
    secondary: types.string,
    error_40: types.string,
  }),
  blue: types.string,
  white: types.model({
    primary: types.string,
    secondary: types.string,
    white_12: types.string,
    white_20: types.string,
    white_90: types.string,
    white_96: types.string,
  }),
  black: types.model({
    primary: types.string,
    secondary: types.string,
  }),
  disable: types.model({
    primary: types.string,
    secondary: types.string,
  }),
  shadow: types.model({
    main: types.string,
    dark: types.string,
    light: types.string,
    weak: types.string,
    darken: types.string,
    barely: types.string,
    dark_3: types.string,
    dark_1: types.string,
  }),
  mask: types.model({
    main: types.string,
    slightlyDark: types.string,
    dark: types.string,
    light: types.string,
    lighter: types.string,
    weak: types.string,
    weaker: types.string,
    barely: types.string,
    slightly: types.string,
    transparent: types.string,
    whiteBasedTransparent: types.string,
    whiteALittleTransparent: types.string,
  }),
  hoverBackground: types.string,
  myTeamAvatarColors: types.array(types.string),
});

export default MThemePalette;

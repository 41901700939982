import React, { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { Title, RichText, Image } from 'components';

import { useEventListener, generateReactKeys } from 'utils';
import store from 'store';
import { TCatalog } from 'types';
import { KeyboardControlKeyCodes } from 'components/Player';
import EmptyPlaceholder from 'components/EmptyPlaceholder';
import FullScreenIcon from 'components/Image/FullScreenIcon';
import CatalogElement from './CatalogElement';
import CatalogContentBlock from './CatalogContentBlock';

import { CatalogImageOverlay, DescriptionWrapper, CatalogElementWrapper } from './Catalog.styles';

interface ICatalog {
  catalog: TCatalog;
}

const Catalog: React.FC<ICatalog> = ({ catalog: currentCatalog }) => {
  const { activePlayerRef, setActivePlayerRef } = store.UIStore.layout;
  const blockKeys = useMemo(() => {
    return generateReactKeys(currentCatalog.groupedByContentChildren.length);
  }, [currentCatalog.groupedByContentChildren]);

  useEventListener('keypress', (e) => {
    if (!activePlayerRef) return;
    switch (e.code) {
      case KeyboardControlKeyCodes.PLAY:
        if (activePlayerRef.paused) {
          activePlayerRef.play();
        } else {
          activePlayerRef.pause();
        }
        break;
      case KeyboardControlKeyCodes.MUTE:
        activePlayerRef.muted = !activePlayerRef.muted;
        break;
      case KeyboardControlKeyCodes.PICTURE_IN_PICTURE:
        if (activePlayerRef.isPiPActive) {
          activePlayerRef.exitPiP();
        } else {
          activePlayerRef.enterPiP();
        }
        break;
      case KeyboardControlKeyCodes.FULLSCREEN:
        if (activePlayerRef.isFullscreenActive) {
          activePlayerRef.exitFullscreen();
        } else {
          activePlayerRef.enterFullscreen();
        }
        break;
      default:
    }
  });

  useEffect(() => () => setActivePlayerRef(null), [setActivePlayerRef]);

  const thumbnail = currentCatalog.coverImage?.url;
  const cover = currentCatalog.fullCoverImage?.url;

  return (
    <>
      {thumbnail && cover && (
        <CatalogImageOverlay>
          <Image thumbnail={thumbnail} original={cover} preview />
          <FullScreenIcon />
        </CatalogImageOverlay>
      )}
      <Title level={1} ellipsis={{ rows: 4 }}>
        {currentCatalog.name}
      </Title>
      {currentCatalog.description && currentCatalog.description !== '' && (
        <DescriptionWrapper>
          <RichText content={currentCatalog.description} />
        </DescriptionWrapper>
      )}
      {!currentCatalog.children?.length && <EmptyPlaceholder showDescription />}
      <CatalogElementWrapper>
        {currentCatalog.groupedByContentChildren.map((contentBlock, i) => (
          <CatalogContentBlock key={blockKeys[i]} type={contentBlock[0]}>
            {contentBlock[1].map((catalogItem) => (
              <CatalogElement key={catalogItem.id} catalogElement={catalogItem} />
            ))}
          </CatalogContentBlock>
        ))}
      </CatalogElementWrapper>
    </>
  );
};

export default observer(Catalog);
